import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2015 - 2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education Quality</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Master's in Computer Application"
            subTitle="Amrita Vishwa Vidyapeetham (2021 - 2023)"
            result="8.4/10"
            // des="The training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="BSc in Computer Science"
            subTitle="Mumbai University (2018 - 2021)"
            result="7.58/10"
            // des="The training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="H.S.C"
            subTitle="S.D.S.M College(2017 - 2018)"
            result="47.5 %"
            // des="Higher education is tertiary education leading to award of an academic degree. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="S.S.C"
            subTitle="Dr. S. D. Vartak Vidyalaya (2015 - 2016)"
            result="64.0 %"
            // des="Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
          />
        </div>
      </div>


      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2021 - Present</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Associate Software Developer"
            subTitle="Nimap Infotech - (Dec, 2023 - Present)"
            result="Mumbai, India"
            // des=""
          />
          <ResumeCard
            title="Data Analyst Intern."
            subTitle="Skill-Lync - (Apr, 2023 - Dec, 2023 )"
            result="Mumbai, India"
            // des=""
          />
          `<ResumeCard
            title="Asst. System Engineer"
            subTitle="VAA technologies - (Sep, 2021 - Dec. 2022)"
            result="Mumbai, India"
            // des=""
          />

        </div>
      </div>
    </motion.div>
  );
}

export default Education